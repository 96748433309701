<template>
<div class="row tgju-widgets-row">
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <div class="tgju-widgets-block col-12 profile-charts" data-target="profile-tour-step-11">
            <div class="tgju-widget light has-icon">
                <h2 class="tgju-widget-title dot m-0">
                    نمودار جامع تحولات سالانه {{ market_charts.info.title }}
                </h2>
                <HighCharts :options="chart_summary_options" v-if="chart_summary_options"></HighCharts>
                <!-- <div id="chart-summary" class="chart-box" style="height: 420px; width: 100%;margin-top: 6px;"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 profile-charts" data-target="profile-tour-step-12">
            <div class="tgju-widget light has-icon">
                <h2 class="tgju-widget-title dot m-0">
                نمودار کندل‌استیک  {{ market_charts.info.title }} در روز جاری
                </h2>
                <HighCharts :options="chart_block_1_options" v-if="chart_block_1_options"></HighCharts>
                <!-- <div id="ChartBlock-1" style="width: 100%; height: 300px; margin: 0 auto;margin-top: -10px;"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 profile-charts" data-target="profile-tour-step-13">
            <div class="tgju-widget light has-icon">
                <h2 class="tgju-widget-title dot m-0">
                نمودار نوسانات {{ market_charts.info.title }} در روز جاری
                </h2>
                <HighCharts :options="chart_block_3_options" v-if="chart_block_3_options"></HighCharts>
                <!-- <div id="ChartBlock-3" style="width: 100%; height: 300px; margin: 0 auto;margin-top: -10px;"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 profile-charts" data-target="profile-tour-step-14">
            <div class="tgju-widget light has-icon">
                <h2 class="tgju-widget-title dot m-0">
                مقایسه روند قیمت {{ market_charts.info.title }} در روز جاری با روز گذشته
                </h2>
                <HighCharts :options="chart_compare_options" v-if="chart_compare_options"></HighCharts>
                <!-- <div id="chart-compare" class="chart-box" style="height: 420px; width: 100%"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 profile-charts" data-target="profile-tour-step-15">
            <div class="tgju-widget light has-icon">
                <h2 class="tgju-widget-title dot m-0"> 

                نمودار کندل استیک سالانه {{ market_charts.info.title }}
                </h2>
                <HighCharts :options="chart_candle_summary_options" v-if="chart_candle_summary_options"></HighCharts>
                <!-- <div id="chart_candle_summary" class="chart-box" style="height: 420px;width: 100%;margin-top: 5px;"></div> -->
            </div>
        </div>

        <!-- TODO: <div class="tgju-widgets-block col-12 profile-charts" data-target="profile-tour-step-16">
            <div class="tgju-widget light has-icon">
                <h2 class="tgju-widget-title dot m-0">
                اندیکاتورهای {{ market_charts.info.title }}
                </h2>
                <HighCharts :options="chart_candle_summary_indicators_options" v-if="chart_candle_summary_indicators_options"></HighCharts>
            </div>
        </div> -->
    </slot>
</div>
</template>

<style lang="scss" scoped>
// thead {
//     display: table-header-group;
//     vertical-align: middle;
//     border-color: inherit;
// }

// .high.dir,
// .low.dir {
//     max-height: 25px;
//     margin: 10px;
// }
</style>

<script>
// در کامپوننت تمام اجزای بخش نمودار پروفایل یک شاخص در بازار را دارا می باشد
// نمودار تمام تواریخ
// نمودار شش ماهه / نمودار سه ماهه
import moment from "moment-jalaali";

// این کامپوننت دارای اجزای ویجت نمودار ها در ویجت ساز داشبورد می باشد .
import HighCharts from '@/plugins/HighCharts.vue'

export default {
    name: 'MarketChartsComponent',
    components: {
        HighCharts,
    },
    data: function () {
        return {
            widgetLoad: true,
            market_charts: [],
            summary_value: [],
            chart_summary_options: null,
            chart_block_1_options: null,
            chart_block_3_options: null,
            chart_compare_options: null,
            chart_candle_summary_options: null,
            chart_candle_summary_indicators_options: null,
        };
    },
    mounted() {
        this.getProfileInformation();
    },
    methods: {
        // این متد برای نمایش زمان به صورت رلتیو می باشد
        time() {
            return moment().format("LTS");
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این متد وظیفه دریافت اطلاعات پروفایل شاخص (جهت نمایش نمودار تغییر در بازه های شش ماهه و سه ماهه و تمام تواریخ ) را دارد
        getProfileInformation() {
            this.$helpers
                .makeRequest("GET", `/market/profile-charts/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_charts = api_response.data.response.detail.response;
                        this.$parent.title = this.market_charts.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;

                        this.chart_summary_options = {
                            chartData: JSON.parse(this.market_charts.summary_chart),
                            chartType: "area",
                            chartTitle: "",
                            yAxisTitle: "",
                            buttonEnabled: true,
                            inputEnabled: true,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            yAxisLineColor: "#feeeec",
                            backgroundColor: false,
                            plotColor: "#892731",
                            markerFillColor: "#feeeec",
                            plotShadow: false,
                            tooltipAdDate: true,
                            navigatorEnabled: true
                            };

                        this.chart_block_1_options = {
                            chartData: JSON.parse(this.market_charts.today_chart_candle),
                            chartType: "candlestick",
                            chartTitle: "",
                            yAxisTitle: "",
                            buttonEnabled: false,
                            tooltipOnlyTime: true,
                            navigatorEnabled: true,
                            plotColor: "#cb494d",
                            upColor: "#56ac53",
                            };

                        this.chart_block_3_options = {
                            chartData: JSON.parse(this.market_charts.today_chart),
                            tooltipTitle: 'قیمت امروز',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            backgroundColor: false,
                            plotColor: "#54a7da",
                            markerFillColor: "#a9c0ce",
                            plotShadow: false,
                            tooltipOnlyTime: true,
                            navigatorEnabled: true,
                            };

                        this.chart_compare_options = {
                            series: JSON.parse(this.market_charts.today_yesterday_chart),
                            chartType: "line",
                            multipleCharts: true,
                            gridLineStyle: "longdash",
                            // compare: 'percent',
                            xAxisGridLineWidth: true,
                            buttonEnabled: false,
                            tooltipOnlyTime: true,
                            legend: true,
                            plotColor: null
                            };

                        this.chart_candle_summary_options = {
                            chartData: JSON.parse(this.market_charts.summary_chart_candle),
                            chartType: "candlestick",
                            chartTitle: "",
                            yAxisTitle: "",
                            buttonEnabled: true,
                            inputEnabled: true,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            yAxisLineColor: "#feeeec",
                            backgroundColor: false,
                            plotColor: "#f45b5b",
                            markerFillColor: "#feeeec",
                            tooltipAdDate: true,
                            navigatorEnabled: true,
                            };

                        this.chart_candle_summary_indicators_options = {};
                    }
                });
        },
        // این متد برای محاسبه دقیق بازه های زمانی و داده ها جهت نمایش رو نمودار مورد استفاده قرار میگیرد
        calculateDifferenceDate(item) {
            var data = this.market_charts.summary_table,
                sd = new Date(
                    new Date().getFullYear() + "-" + item + "-" + new Date().getDate()
                ).getTime(),
                ed = new Date(new Date().toJSON().slice(0, 10)),
                result = data.filter((d) => {
                    var time = new Date(d.date.replace("/", "-")).getTime();
                    return sd < time && time < ed;
                });

            var sum = 0;
            result.forEach((item) => {
                sum += parseFloat(item.open.replaceAll(",", ""));
            });

            var month_average = parseInt(sum / result.length);
            var difference_between_today_balance_and_average_of_months = parseInt(
                this.market_charts.summary.price.plain - month_average
            );

            var percentage_of_difference_between_today_balance_and_average_of_months =
                parseFloat(
                    ((this.market_charts.summary.price.plain - month_average) /
                        this.market_charts.summary.price.plain) *
                    100
                );

            return {
                month_average: month_average,
                difference: difference_between_today_balance_and_average_of_months,
                difference_percentage: percentage_of_difference_between_today_balance_and_average_of_months.toFixed(
                    2
                ),
                items: result,
            };
        },
    },
};
</script>
